/* Adjustements for different viewports
-------------------------------------------------- */

/*
 * Link related style definitions
 */

@media (max-width: @screen-xs-min) {

  // shrink the logo on 480px
  .navbar-default .navbar-brand {
    width: 80%;
  }

  // remove background and padding of subsegment heading
  .as_subsegment_heading {
    background: none;
    padding: 0;
  }

  .dashboard-group-list {

    .hsnet-group-link {
      font-size: @font-size-base;
      font-weight: @font-weight-medium;

    }
  }
}

@media (min-width: @screen-md) and (max-width: @screen-md-max) {
  .search-help {
    font-size: @font-size-small;
  }
}

@media (max-width: @screen-sm-max) {
  .app-role-heading {
    font-size: @font-size-large;
  }

  .navbar-nav.with_custom_mod {
    a {
      font-size: @font-size-base;
    }
  }
}

@media (min-width: @screen-xs) {

  .navbar-brand {
    padding-bottom: ceil((@navbar-header-height - @navbar-brand-image-height)/2);
  }

  .as_segment_container {
    margin: @as-segment-margin;
  }

  .as_segment {
    padding: 10px 20px;
    height: @as-segment-height-sm;
  }

  .or-circle {
    top: @as-segment-height-sm + @as-segment-border-width + @as-segment-margin - @as-circle-height/2;
    left: 46%;
  }

  // Footer links
  .footer-nav {
    margin-top: (@footer-height - 2*@footer-padding - @line-height-computed)/2;

    li {
      border-left: 1px solid @nav-tabs-border-color;
      padding-left: 10px;
      padding-right: 10px;
      margin-right: 0;
      float: left;

      &:first-child {
        border-left: 0;
        padding-left: 0;
      }
    }

    a {
      color: @body-bg;
    }
  }
}

@media (min-width: @screen-sm-min) {

  .wrap-default-height, footer, .navbar-collapse {
    > .container {
      padding-left:0;
      padding-right:0;
    }
  }

  .logo-nav {
    margin-left: -1px;
  }

  .navbar-nav.with_custom_mod {
    > li:first-child {
      margin-left: -@nav-link-padding-horizontal;
    }
  }

  .fa-medium {
    font-size: 2em;
  }

  .control-label.text-left {
    text-align: left;
  }


  .lead {
    font-size: ceil(@font-size-base * 1.285714); // 18px
  }

  .container-spaceless {
    padding: 0;
  }

  .container-top-padding {
    padding-top: @space-below-main-nav;
  }

  .no-top-margin {
    margin-top: 0;
  }

  .no-bottom-margin {
    margin-bottom: 0;
  }

  .as_segment {
    padding: @as-segment-padding-md @as-segment-padding-md 0 @as-segment-padding-md;
  }

  .as_segment_heading {
    margin: 0 0 20px 0;
  }

  .carousel-indicators {
    li, .active {
      width: 10px;
      height: 10px;
      background: none;
      border-width: 0;
      margin: 1px;
    }

    a {
      color: @gray-light;

      &:hover, &:focus {
        text-decoration: none;
      }
    }

    .fa-circle {
      display: none;
    }

    .fa-circle-o {
      display: inline;
    }

    .active, a:hover, a:focus {
      .fa-circle {
        display: inline;
      }
      .fa-circle-o {
        display: none;
      }
    }

    .active > a, a:active {
      color: @btn-default-bg;
    }

  }

  .indicators-below-carousel {
    bottom: -36px;
  }

  .indicators-on-right {
    left: 38%;
    margin-left: 0;
    text-align: right;
  }

  .col-for-button {
    padding-left: 0;
  }

  .dashboard-group-heading {
    > small {
      font-size: @font-size-base;
    }
  }


  .dashboard-group-list-item {
    width: auto;
  }

  .dashboard-group-invitation-icon {
    display: block;
    margin: 30px auto 5px auto;
  }

  .dashboard-group-list-item-link {
    height: @dashboard-group-item-height-sm;
    padding-right: 50px !important;
    padding-left: 50px !important;
    width: @dashboard-group-item-width-sm;
  }

  .dashboard-group-list-item-info {
    max-height: @dashboard-group-item-height-sm - (2 * @nav-link-padding-vertical);
  }

  .group-public {
    display: block;
    padding-top: 15px;
    border-top: 1px solid @nav-tabs-border-color;
    width: 50%;
    margin: 15px auto auto;
    color: @gray-light;
    font-size: @font-size-small;
    font-weight: normal;
  }

  .dashboard-group-create {
    width: @dashboard-group-item-width-sm;
    .dashboard-group-list-item-info {
      display: table;
    }
  }
  .dashboard-group-list-item-link-create {
    border: 3px dashed @link-color;
    border-radius: 50%;
    text-align: center;
    width: @dashboard-group-item-height-sm;
    margin: 0 auto;
  }

  .left-border-sm {
    border-left: 1px solid @nav-tabs-border-color;
  }
}

@media (min-width: @grid-float-breakpoint) {
  .navbar-header {
    height: @navbar-header-height;
  }
}

@media (min-width: @screen-md) {

  #main {
    top: @main-pullup-distance;
  }

  .fa-medium {
    font-size: 2.5em;
  }

  .or-circle {
    top: (@as-segment-height-lg + @as-segment-border-width * 2 + @as-segment-margin * 2 - @as-circle-height) / 2 ;
    left: 91%;
  }

  .as_segment_container {
    margin-left: 0;
  }

  .as_segment {
    padding-bottom: @as-segment-padding-md;
    height: @as-segment-height-lg;
  }

  .as_segment_heading {
    margin-right: 40px;
    margin-left: 40px;
  }

  .as-segment-bottom-block {
    bottom: 0;
  }

  .link-explore {
    margin-top: 15px;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  .as-circle-stat {
    margin: auto;
  }

  .col-double-padding-sm {
    padding: 30px;
  }

  .top-margin-sm {
    margin-top: 15px;
  }

  .top-margin-md {
    margin-top: 30px;
  }

  .top-margin-lg {
    margin-top: 50px;
  }

  .dashboard-group-list-item-link {
    font-size: @font-size-large;
    font-weight: @font-weight-medium;
    height: @dashboard-group-item-height-md;
    width: @dashboard-group-item-width-md;

    .transition(background-color 0.3s, border-color 0.3s;);
  }

  .dashboard-group-list-item-info {
    max-height: @dashboard-group-item-height-md - (2 * @nav-link-padding-vertical);
  }

  .dashboard-group-invitation-icon {
    margin-top: 30px;
  }

  .dashboard-group-create {
    width: @dashboard-group-item-width-md;
  }

  .dashboard-group-list-item-link-create {
    width: @dashboard-group-item-height-lg;
  }

}

@media (min-width: @screen-lg) {

  .or-circle {
    left: 93%;
  }


  .as_segment {
    padding: @as-segment-padding-lg @as-segment-padding-lg (2/3 * @as-segment-padding-lg) @as-segment-padding-lg;
  }

  .as-circle-stat {
    width: @stat-circle-height-lg;
    height: @stat-circle-height-lg;
    margin: auto;

    &.stat-three-lines {
      .as-circle-stat-padding(@stat-circle-height-lg, 3);
    }

    &.stat-four-lines {
      .as-circle-stat-padding(@stat-circle-height-lg, 4);
    }

  }

  .top-margin-sm {
    margin-top: 20px;
  }

  .top-margin-md {
    margin-top: 30px;
  }

  .top-margin-lg {
    margin-top: 50px;
  }

  .fa-medium {
    font-size: @font-size-base * 4;
  }

  .dashboard-group-list-item-link {
    height: @dashboard-group-item-height-lg;
  }

  .dashboard-group-list-item-info {
    max-height: @dashboard-group-item-height-lg - (2 * @nav-link-padding-vertical);
  }

  .dashboard-group-invited {
    .dashboard-group-list-item-info {
      // accommodate for the invitation icon
      max-height: @dashboard-group-item-height-lg - (2 * @nav-link-padding-vertical) - 45 - (@font-size-large * @line-height-base);
    }
  }
  .dashboard-group-invitation-icon {
    margin-top: 40px;
  }

}

.app-role-heading {

  @media (min-width: @screen-md) {
    font-size: @font-size-xlarge;
    padding-top: floor((@navbar-header-height - @font-size-xlarge)/2) - 2;
    height: @navbar-header-height;
  }
}


.nav-tabs-justified {

  @media (min-width: @screen-sm-min) {

    > li {
      border-right-color: transparent;

      &.active {
        border-right-color: @component-active-bg;

        &> a {
          border-color: @component-active-bg;
        }
      }
    }

    .active + li {
      border-left: 1px solid @component-active-bg;
    }
  }
}
