//
// Badges custom overwrites
// --------------------------------------------------


// Account for counters numbers only in navs
.active > a > .badge {
  color: @badge-active-color;
  background-color: @badge-active-bg;
}

li > a > .badge {
  margin-left: 3px;
  position: relative;
  top: -1px;
}