/**
* A vertical list component for Bootstrap
*/

.ix-vertical-list {
  overflow: auto;
  position: relative;
  padding-left: 0;

  li {
    list-style: none;
    float: left;
    width:100%;
  }

  .ix-list-item {
    float: left;
    padding: 15px 0 15px 15px;
    color: inherit;
    display:block;
    width:100%;
  }

  .event-item-date {
    border: 1px solid @color-swatch-b;
    padding: 5px;

    .h1 {
      margin-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }

    .subhead {
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }


  .event-item-details {

    > h3 {
      margin-top: 0;
    }

    > p {
      margin-bottom: 0;
    }


    @media (min-width: @screen-sm-min) {
      padding-left: 15px;

      > h3 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

  }
}

.ix-list-item-hover {

  .ix-list-item {

    &:hover, &:focus {
      background-color: @gray-lightest;
      text-decoration: none;
    }
  }
}

.hsnet-events-scroller {
  max-height: 355px;

  .list-group-v-boundary();

  .ix-list-item-hover();

  @media (min-width: @screen-xs-min) {
    max-height: 298px;
  }

  @media (min-width: @screen-lg) {
    max-height: 400px;
  }
}

