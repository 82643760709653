/*  Styles related to overview page */

.overview-page-btn {
  color: @text-color;
}

.overview-service-directory-circle,
.overview-ereferral-circle,
.overview-groups-circle  {
  .transition(color 300ms;);
}

.overview-service-directory-circle {
  color: @color-swatch-b;
}

.overview-ereferral-circle {
  color: @color-secondary-swatch-a;
}

.overview-groups-circle {
  color: @color-secondary-swatch-f;
}

.overview-page-btn {
  &:hover, &:focus {
    .overview-service-directory-circle {
      color: darken(@color-swatch-b, 10);
    }

    .overview-ereferral-circle {
      color: darken(@color-secondary-swatch-a, 10);
    }

    .overview-groups-circle {
      color: darken(@color-secondary-swatch-f, 10);
    }

  }
}

.overview-service-directory-icon {
  left: 78px;
}

.overview-ereferral-icon {
  left: 70px;
}

.overview-groups-icon {
  left: 69px;
}

.overview-module-title {
  padding-left: 30px;
  padding-right: 30px;
}

.overview-module-title-condensed {
  padding-left: 50px;
  padding-right: 50px;

}
