//
// Navs custom overwrites
// --------------------------------------------------



/**
  * Overwrites
  */

.navbar {
  border-bottom: 0;
}

.nav {
  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: @nav-link-hover-bg;
      border-color: @color-swatch-e; //> @link-color;
      border-bottom-color: transparent;

      .caret {
        border-top-color: @link-hover-color;
        border-bottom-color: @link-hover-color;
      }
    }
  }
  //+
  .open.active > a {
    .caret {
      border-top-color: #fff;
      border-bottom-color: #fff;
    }
  }
}

.nav-pills, .nav-tabs {

  border-left: 1px solid @nav-tabs-border-color;

  & >li {

    border-top: 1px solid @nav-tabs-border-color;
    border-right: 1px solid @nav-tabs-border-color;

    &.active {
      border-color: @component-active-bg;
    }


    > a {

      padding-top: @nav-vertical-padding + 1;
      padding-bottom: @nav-vertical-padding;
      margin-right: 0;
      color: @nav-tabs-link-color;
      border-width: 0;

      &:hover, &:focus {
        color: @component-active-bg;
      }
    }

  }

}

.nav-tabs-justified {
  border-width: 0px;

  > li {

    border: 1px solid @nav-tabs-border-color;

    &:last-of-type {
      border: 1px solid @nav-tabs-border-color;
    }

    >a  {
      padding: @module-nav-item-vertical-padding - 1;
      margin-bottom: 0;
    }
  }

  &> .active {
    background-color: @component-active-bg;
    border: 1px solid @component-active-bg;
  }
}

.nav-tabs-justified, .nav-stacked {
  > li > a,
  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    border-width: 0;
  }
}

// Dropdowns
// -------------------------

// Make dropdown carets use link color in navs
.nav .active .caret {
  border-top-color: #fff; //> @link-color;
  border-bottom-color: #fff; //> @link-color;
}
.nav .active a:hover .caret {
  border-top-color: #fff; //> @link-hover-color;
  border-bottom-color: #fff; //> @link-hover-color;
}


/**
  * Custom definitions
  */
