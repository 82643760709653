/* Sticky footer styles
-------------------------------------------------- */

html,
body {
  height: 100%;
  /* The html and body elements cannot have any padding or margin. */
}

@footer-height:     80px;
@footer-margin:     50px;
@footer-padding:    10px;
@footer-logo-size:  52px;

// actual vertical space required to display footer with a margin
@footer-box-height: @footer-height + @footer-margin;

/* Wrapper for page content to push down footer */
#wrap {
  min-height: 100%;
  height: auto;
  /* Negative indent footer by its height */
  margin: 0 auto -@footer-box-height;
  /* Pad bottom by footer height */
  padding: 0 0 @footer-height + @main-pullup-distance;
}

.wrap-default-height {
  padding: 0 0 @footer-box-height;
}

/* Set the fixed height of the footer here */
#footer {
  height: @footer-height;
  padding: @footer-padding;
  margin-top: @footer-margin;
}

.merge-footer-margin {
  margin-bottom: -@footer-margin;
  overflow: auto;
}

.footer-margin-space {
  padding-bottom: @footer-margin;
}
