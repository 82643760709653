/* Style for Drupal elements
----------------------------- */

.tabs--primary {
  margin-bottom: @headings-margin-top;
}

/* Miscellaneous custom definitions
-------------------------------------------------- */

b, strong {
  font-weight: 600;
}

.panel-body {
  padding: @panel-padding-sm;
}

.form-control {
  .box-shadow(none);
}

.form-control-hint {
  display: inline-block;
  margin-top: 3px;
}

select.form-control {
  padding-top: @padding-base-vertical - 1 ;
  padding-bottom: @padding-base-vertical - 1 ;
}

.form-group {
  margin-bottom: @form-group-margin-bottom;
}

label {
  font-weight: normal;
}

input[type="file"] {
  padding-top: 3px;
  height: auto;
  max-height: @input-height-base;
}

.list-textual {
  -o-padding-start: 20px;
  -ms-padding-start: 20px;
  -moz-padding-start: 20px;
  -webkit-padding-start: 20px;

  li {
    padding-bottom: 3px;
  }
}

.input-group-addon {
  background-color: transparent;
}

.input-group .form-control:first-child {
  border-right-color: transparent;
}

.form-horizontal {
  .checkbox {
    padding-top:0;
  }

  .stacked-label {
    margin-bottom: 7px;
  }
}

thead {
  th {
    text-transform: uppercase;
    font-weight: 600;
  }
}

.table-bordered {
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }
}

.table-below-tab {
  border-top-color: @component-active-bg;
  margin-top: -1px;
}

.table-with-pagination {
  margin-bottom: @paragraph-margin-bottom;
}

.table-pagination {
  .table-entries-count {
    padding-top: @padding-base-vertical;
  }

  .pagination {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.row-attachment-item {
  .row-attachment {
    margin-bottom: 10px;
  }
}

.fa-xs-normal-size {
  font-size: @font-size-base;
}

.DropShadow {
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

.tt-dropdown-menu {
  .DropShadow;
}

.btn-primary-dark {
  .button-variant(@btn-default-color; @color-swatch-a; @btn-default-border);
}

 /*************************************************
  * Site high contrast mode
  ************************************************/
.hsnet_contrast {

  .alert-success {
    color: black;
  }

  .dashboard-group-list-item-link-normal:hover,
  .dashboard-group-list-item-link-normal:focus {
    background-color: darken(@color-swatch-b, 10%) !important;
  }

  .group-public,
  .subhead,
  .nav-pills > li > a,
  .nav-tabs > li > a {
    color: #666;
  }

  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:hover,
  .nav-tabs>li.active>a:focus {
    color: white;
  }

  #brightness-btn i {
    color: #DD0000;
  }

  .footer-nav a {
    color: white;

    &:hover {
      color: white;
    }
  }

  a:hover {
    color: inherit;
  }

  .btn-primary,
  .btn-info,
  {
    background-color: darken(@color-swatch-b, 15%);
    border-color: darken(@color-swatch-b, 15%);
    color: white;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-info:hover,
  .btn-info:focus,
  {
    background-color: darken(@color-swatch-b, 25%);
    border-color: darken(@color-swatch-b, 25%);
  }

  a {
    color: darken(@color-swatch-b, 15%);

    &.link-light {
      color: white;
    }

    &.main-header-btn,
    &.link-dark {
      color: black;
    }
  }

  .boxes-box-content a:hover,
  .boxes-box-content a:focus,
  {
    color: darken(@color-swatch-b, 25%);
  }

  .pagination > li > a,
  .pagination > li > span,
  {
    color: #636363;
  }

  .app-role-heading {
    color: #595959;
  }

  .as_segment_one {
    background-color: darken(@color-secondary-swatch-f, 10%);
  }

  .as_segment_two {
    background-color: darken(@color-swatch-a, 10%);
  }

  .as_segment_one.as_segment_one_faded {
    background-color: darken(@color-secondary-swatch-f, 20%);
  }

  .as_segment_two.as_segment_two_faded {
    background-color: darken(@color-swatch-a, 20%);
  }

/* Handling input placeholder text */
  [type="search"] {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    color: #666;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #666;
    opacity: 1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #666;
    opacity: 1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #666;
  }
}


 /*************************************************
  * Global Error Message
  ************************************************/
.alert.with_custom_mod {
  margin-bottom: 0;
}

 /*************************************************
  * Height Fullscreen
  ************************************************/
 #wrap.as_height_fullscreen,
 .as_height_fullscreen {
    height: 100%;
 }

 /*************************************************
  * Map
  ************************************************/

/* Ensure constant display of the scrollbar */
.as_map_body {
  overflow-y: scroll;
}

  /* Search */
.as_map_search_container {
    position: fixed;
    z-index: 2012;
    left: 0;
    right: 0;
}

  .as_map_search {
    width: auto;
    height: 94px;

    background-color: #00205B;

    -webkit-box-shadow: 0 3px 3px 0 rgba(0,0,0,0.6);
    box-shadow: 0 3px 3px 0 rgba(0,0,0,0.6);

    color: #ffffff;
  }

    .as_map_search_input {
      margin-top: 24px;
    }

.as_map_link_bookmark {
  color: #ffffff;
}

/* Footer */
.as_map_footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2011;
}

/* Tiles */
.as_map_tiles_container {
  position: fixed;
  top: 97px;
  bottom: 60px;
  z-index: 2008;
}

.as_map_tiles {
  width: auto;
  height: 100%;
}

/* Sidebar and bookmark container */
.as_sidebarbookmark_container {
  overflow: hidden;
  height: 100%;
}

/*************************************************
* Map - Bookmark
************************************************/

/* Bookmark */
.as_map_bookmark_container {
  top: 94px;
  z-index: 2010;
  height: 100%;

  background-color: #ffffff;
}

.as_map_link_bookmark_container {
  padding-top: 17px;
  height: 100%;

  border-left: 1px solid #ffffff;
}

.as_map_bookmark_icon {
  font-size: @font-size-h1;
}

/*************************************************
* Map - Sidebar, search result
************************************************/

/* Sidebar */
.as_map_sidebar_container {
  position: absolute;
  padding: 13px 10px 63px 10px;
  top: 94px;
  margin-left: 20px;
  height: 100%;
  z-index: 2009;

  background-color: rgba(54, 58, 54, 0.4);
}

/* Sidebar searhc item */
.as_map_sidebar_list {
  list-style: none;
  padding: 0;
}

.as_map_sidebar_item_header {
  background-color: #84BD00;
}

.as_map_sidebar_item_header_content {
  margin: 0;
  padding: 19px 10px 13px 10px;
  font-size: @font-size-base;
}

.as_map_sidebar_item_content {
  padding: 11px 10px 15px 10px;
  background: #ffffff;
}

.as_map_sidebar_item_service {
  font-size: @font-size-base;
}


/*
 * Navbar customisation
 */

.navbar {
  background-color: #F5F5F5;
  border-top: @navbar-border-top-width solid #CCCCCC;
}

.navbar-brand {
  padding-top: ceil((@navbar-header-height - @navbar-brand-image-height)/2);
  padding-bottom: 0;
}

.navbar-nav.with_custom_mod {
  .active:before {
    border-top: @navbar-border-top-width solid @navbar-default-link-active-color;
    content: " ";
    position: absolute;
    top: -@navbar-border-top-width;
    width: 100%;
  }

  a {
    font-size: @font-size-large;
  }

  & .fa-caret-down {
    display: none;
    position: absolute;
    left: 46%;
    font-size: @nav-caret-font-size;
    top: -20%;
  }

  &> .active .fa-caret-down  {
    display: block;
  }

  &> .active > a:hover {
    color: @navbar-default-link-active-color;
  }
}

.navbar-toggle {
  float: left;
}

/*
 * Dropdown customisations
 */

// The dropdown menu (ul)
.dropdown-menu.with_custom_mod {
  .box-shadow(0 0 0 rgba(0,0,0,0)); //> .box-shadow(0 6px 12px rgba(0,0,0,.175));
  border-right-color: @color-swatch-e;
  border-bottom-color: @color-swatch-e;
  border-left-color: @color-swatch-e;
}

.navbar-toggle {
  margin: 20px;
}

/*
 * Link related style definitions
 */

// Remove dotted focus outlines on links (Firefox)
a:focus {
    outline: none;
}

// Link with an icon on the left
// example: <a href="#" class="with_icon"><i class="fa fa-arrow-right"></i>Link to...</a>;
.with-icon {
  i {
    padding-right: @inline-icon-padding;
    color: @link-color;
  }
}

.btn.with-icon {
  i {
    color: inherit;
  }
}

.link-dark {
  &,
  i,
  &:hover,
  &:focus
  {
    color: @gray-darker;
  }
}

.link-light {
  &,
  i,
  &:hover,
  &:focus
  {
    color: #fff;
  }
}

.link-attention {
  &,
  i,
  &:hover,
  &:focus
  {
    color: @color-swatch-d;
  }
}

.link-danger {
  &:hover,
  &:focus
  {
    color: @color-swatch-d;
  }
}

.light-bg {
  background-color: #F1F9FC;
}

/*
 * Typography
 */

html {
  -webkit-font-smoothing: antialiased;
}


body {
  font-weight: @font-weight-normal;
}

h1, h2, h3 {
  margin-top: @headings-margin-top;
  margin-bottom: @headings-margin-bottom;
}


h1, h2 {
  &.underline {
    padding-bottom: @headings-margin-bottom;
    border-bottom: 1px solid @color-swatch-b;
  }
}

h1, .h1 {
  line-height: @line-height-h1;
}

h2, .h2 {
  line-height: @line-height-h2;
}

h3 {
  &.underline {
    margin-bottom: @paragraph-margin-bottom;
    padding-bottom: floor(@paragraph-margin-bottom / 2);
    border-bottom: 1px solid @nav-tabs-border-color;
  }
}

h3, .h3 {
  font-weight: @font-weight-medium;
  line-height: @line-height-h3;
}

h4, .h4 {
  line-height: @line-height-h4;
}


p {
  margin: 0 0 @paragraph-margin-bottom 0;
}

hr {
  margin-top: @headings-margin-top;
}

.subhead {
  font-size: round(@font-size-h1 / 2);
  color: @gray-light;
  font-weight: @font-weight-normal;
  font-family: @font-family-base;
  text-transform: uppercase;
  margin: -3px 0 @headings-margin-bottom 0;
  letter-spacing: normal;

  .fa {
    padding-right: @inline-icon-padding;
  }

  + .subhead {
    margin-top: -@headings-margin-bottom;
  }
}

.subhead-small {
  text-transform: none;
  margin-top: -6px;
}

.subhead-small-font {
  font-size: 85%;
  line-height: 19/12;
}

h1, h2, h3 {
  & + .subhead-small-font {
    margin-top: -@headings-margin-bottom;
  }
}

.lead {
  font-family: @headings-font-family;
  font-size: 18px;
  font-weight: 300;
}


.list-group {
  &> li {
    & >h3 {
      margin-top: 12px;
    }
  }
}

.list-group-v-boundary {
  border-top: 1px solid @nav-tabs-border-color;
  border-bottom: 1px solid @nav-tabs-border-color;
}

.list-group-bordered {
  &> li {
    border-bottom: 1px dotted #cdcdcd;
  }
}


/* Adjustments for Open-Sans */
h1, .h1{
  letter-spacing: -1px;
  font-weight: 400;
}

h2, .h2{
  letter-spacing: -0.5px;
}

.tab-content {
  margin-bottom: @headings-margin-bottom;
}

.input-group-addon {
  .fa {
    color: @input-border;
  }
}

/*
 * Custom header
 */
header.with_custom_mod {
  background-color: @gray-lighter;
}

.container-top-margin {
  margin-top: @space-below-main-nav;
}

.container-top-padding {
  padding-top: @space-below-main-nav;
}

.main-header {
  .header-logo-container {
    width: auto;
  }

  .navbar-icon-buttons {
    font-size: 28px;
    float: right;
    margin-right: 15px;
    margin-top: 24px;

    a {
      text-decoration: none;
    }
  }
}

.main-header-btn {
  cursor: pointer;
  color: @btn-default-bg;

	&:focus,
	&hover {
		color: #808080;
	}

	&:focus i {
	outline: 2px solid #999;
	}
}

.app-role-heading {
  color: @app-role-heading-color;
  vertical-align: middle;

  @media (max-width: 991px) {
    margin-top: 28px;
  }

  >span {
    margin-right: 5px;
    font-family: @headings-font-family;
    padding-right: 15px;
  }
}

.medium-weight {
  font-weight: @font-weight-medium;
}

.full-width-image {
  width: 100%;
}

/*
 * Outdated browser warning
 */
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/*
 * Make the carousel fade instead of slide
 * http://codepen.io/Rowno/pen/Afykb
 */

.carousel-fade {
  .carousel-inner {
    .item {
      .opacity(0);
      transition-property: opacity;
    }

    .active {
      .opacity(1);
    }

    .active.left,
    .active.right {
      left: 0;
      .opacity(0);
      z-index: 1;
    }

    .next.left,
    .prev.right {
      .opacity(1);
    }
  }

  .carousel-control {
    z-index: 2;
  }
}

.pins-overlay {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}


/*
 * Segmentation pages and their blocks definitions
 */

#main {
  position: relative;
  top: @main-pullup-distance * 0.5;
}

.as_segment_container {
  border: @as-segment-border-width solid rgb(0, 0, 0);
  border: @as-segment-border-width solid rgba(0, 0, 0, .2);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
  margin: 0 0 @as-segment-margin 0;
  padding: 0;
}

.as_segment {
  background-color: #fff;
  padding: 10px;
  color: white;
  text-align: center;
  height: @as-segment-height-xs;
}

.as_segment_heading {
  font-weight: normal;
  font-size: @font-size-base * 1.7;
  color: white;
  margin: 20px 10px 20px 10px;
}

.as_segment_heading2 {
}

.as_segment_one {
  background-color: @color-secondary-swatch-f;
  .as_segement-transition-bck;
}

.as_segment_two {
  background-color: @color-swatch-a;
  .as_segement-transition-bck;
}

/* Class injected in JS, changes background for segment */
.as_segment_one.as_segment_one_faded {
  background-color: darken(@color-secondary-swatch-f, 10%);
  .as_segement-transition-bck;
}

.as_segment_two.as_segment_two_faded {
  background-color: darken(@color-swatch-a, 10%);
  .as_segement-transition-bck;
}



/* Class for CSS3 animation, transition for background color */
.as_segement-transition-bck {
  .transition(all 0.2s ease-in);
}

.as_segment_content {
  position: relative;
  min-height: 180px;
  height:100%;
}

.as_segment_content .medium-weight {
  line-height:  1.42857;
}

/* Link within boxes located on the home page */
.as_segment_link {
  display: block;
  bottom: 20px;

  color: white;
}

.as_segment_link:hover {
  text-decoration: none;
  color: white;
}

.as_segment_link_text {
  padding-left: 15px;
  padding-right: 15px;
}


.login-btn {
  width: 100%;
}

.as-segment-bottom-block {
  position:absolute;
  width: 100%;
  margin: 0;
  bottom: ceil(@as-circle-height / 2);
}

.as_subsegment_heading {
  margin: 10px 0 0;
  padding: 1px 20px 9px 20px;
  background: #fff;
  background:rgba(255,255,255,0.8);
}

.col-spaceless {
  padding: 0;
}

.search-help {
  color: white;
  text-align: left;
}

.search-btn {
}

.search-bar {
}


.secondary-actions-row {
  height: @secondary-action-row-height;
  line-height: @secondary-action-row-height;

  .btn-search-group {
    margin-top: (@secondary-action-row-height - @btn-circular-medium-size) /2;
  }
}

.btn-circular {
  position: relative;
  display: inline-block;
  color: @link-color;

  .transition(color 0.3s;);

  > .place-in-circle {
    position: absolute;
    color: @body-bg;
  }

  &:hover, &:focus {
    color: darken(@link-color, 10%);
  }
}

.btn-circular-medium {
  height: @btn-circular-medium-size;

  > .fa-circle {
    font-size: @btn-circular-medium-size;
  }

  > .place-in-circle {
    font-size: 24px;
    color: white;
    left: 12px;
    top: 12px;
  }
}

.btn-circular-large {
  height: @btn-circular-large-size;

  > .fa-circle {
    font-size: @btn-circular-large-size;
  }

  > .place-in-circle {
    font-size: 72px;
    color: white;
    top: 85px;
  }
}

.btn-block {
  display: block;

  &:hover, &:focus {
    text-decoration: none;
  }

  &.btn-circular-medium {
    height: auto;
  }

}

.form-action-link {
  display: block;
  padding-top: @padding-base-vertical;
}

/*
 * Footer
 */

footer {
  background-color: @btn-default-bg;
}

 // Footer links
.footer-nav {
  padding: 0;
  list-style: none;
  margin-bottom: 0;

  a {
    color: @body-bg;
  }
}

.footer-logo {
  margin-top: (@footer-height - 2*@footer-padding - @footer-logo-size)/2;
}


/*
 * Circle stats
 * example:
 * <div class="as-circle-stat">
 *   <p class="as_text">Services in our database</p>
 *   <p class="as-stat-info">326,546</p>
 * </div>
 */

.as-circle-stat-padding(@circle-height, @line-count) {
  padding-top: round((@circle-height - @font-size-h1 * @line-height-h1 * @line-count) / 2);
}


.as-circle {
  text-align: center;
  margin: 0 auto auto;
  .lh-border-radius(50%);

}

.as-circle-stat {
  .as-circle;
  .h1;
  font-weight: 400;
  border: 3px solid #000;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 70px 20px;
  margin: 10px auto 10px auto;
  width: @stat-circle-height-sm;
  height: @stat-circle-height-sm;

  &.green {
    border-color: @color-secondary-swatch-e;
    background-color: rgba(132, 189, 0, 0.1);
  }

  &.yellow {
    border-color: @color-secondary-swatch-a;
    background-color: rgba(255, 184, 28, 0.1);
  }

  &.fullgreen {
    border-color: @color-secondary-swatch-f;
    background-color: @color-secondary-swatch-f;
    color: #ffffff;
  }

  &.blue {
    border-color: @color-swatch-c;
    background-color: white;
  }

  .as_text{
    margin: 0 0 5px 0;
    padding: 0 15px;
  }

  .as-stat-info {
    font-weight: @font-weight-medium;
  }

  &.stat-three-lines {
    .as-circle-stat-padding(@stat-circle-height-sm, 3);
}

  &.stat-four-lines {
    .as-circle-stat-padding(@stat-circle-height-sm, 4);
  }

}

.or-circle {
  .as-circle;
  height: @as-circle-height;
  width: @as-circle-height;
  font-size: @as-circle-font-size;
  font-weight: bold;
  color: @gray-dark;
  border: 0;
  margin: 0;
  padding: floor((@as-circle-height - @as-circle-font-size - 4) / 2) 0 0 0;
  background-color: #fff;
  position: absolute;
  z-index: 123;
  top: @as-segment-height-xs + @as-segment-border-width + @as-segment-margin - @as-circle-height/2;
  left: 40%;
}

/*
 * Various custom elements
 */
.with_top_padding {
  padding-top: 15px;
}


/*
 * Search box styles
 */
.as_search_box_wrap {

}


/*
 * Sidebar Navigation
 */

.nav-stacked {
  padding-left: 0;
  border-left: 0;


  > li {
    border-right: 0;


    + li {
      margin-top: 0;
    }
  }


  li {
    position:relative;
    border-top: 1px solid @nav-tabs-border-color;

    > a {
      padding-top: @module-nav-item-vertical-padding;
      padding-bottom: @module-nav-item-vertical-padding - 1; // Adjust 1px top border
    }

    &:last-of-type {
      border-bottom: 1px solid @nav-tabs-border-color;

      > a{
        padding-top: @module-nav-item-vertical-padding - 1; // last item has both top and bottom border
      }
    }

    .fa-caret-right {
      position: absolute;
      left: 99.7%;
      top: ceil(((@font-size-base * @line-height-base) + (2*@module-nav-item-vertical-padding) - (@nav-caret-font-size))/2) ;
      font-size: @nav-caret-font-size;
      color: @nav-pills-active-link-hover-bg;
      display: none;
    }

  }

  .active {
    border-top: 1px solid @component-active-bg;
    a, a:hover, a:visited{
      //      background-color: @component-active-bg;
      //      color: @component-active-color;
    }

    .fa-caret-right {
      display: block;
    }
  }
}


.pagination-container {
  margin-top: 30px;
  &> .pagination {
    margin-top: 0;
  }
}

.pagination {
  > li > a,
  > li > span {
    color: @pagination-color;
    padding: 7px 13px;
    border: 0px solid #fff;

    &:hover,
    &:focus {
      color: @pagination-active-color;
    }
  }
}

.row-buttons {
  margin-top: @form-group-margin-bottom * 2;
}

.panel-body .form-horizontal {
  .row-buttons, .form-group-last {
    margin-bottom: 0;
  }
}

.dependant-elements {
  margin-left: 30px;
}
.first-row {
  margin-top: 80px;
}

.mandatory-field {
  color: #CE2732;
}

.panel-opener {
  margin-bottom: -1px;

  h1,h2,h3 {
    &:first-child {
      margin-top: 0;
    }
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

}

.no-right-padding {
  padding-right: 0;
}

.no-left-margin {
  margin-left: 0;
}

.module-title {
  margin-bottom: @breadcrumb-container-height-sm;

  + .breadcrumb-container {
    margin-top: -(@breadcrumb-container-height-sm);
  }
}

.breadcrumb-container {
  height: @breadcrumb-container-height-sm;
}

.breadcrumb {
  padding: @breadcrumb-item-vertical-padding 0;
  margin: @breadcrumb-container-vertical-margin-sm 0 ;

  li {
    display: inline-block;
    text-shadow: 0 1px 0 #ffffff;
  }

  .divider {
    padding: 0 5px;
    color: #999999;
  }

  .active a {
    color: #333333;

    &:hover, &:focus {
      text-decoration: none;
      cursor: default;
    }
  }

}

.panel-for-form {
  .panel-heading {
    background-color: inherit;
    border-bottom-width: 0;
    padding: @panel-padding-sm @panel-padding-sm @headings-margin-bottom @panel-padding-sm;
  }

  h3.panel-title {
    font-size: @font-size-h3;
  }

  .panel-body {
    padding-top: 0;
  }
}

.panel-collapsible {
  overflow: hidden;

  .panel-heading {
    position: relative;
  }

  &.panel-collapsed {

    /* Hide all form-group elements in a collapsed panel */
    .form-group {
      display: none;
    }

    /* leaving only the ones wh need to be visible */
    .visible-when-collapsed {
      display: block;
    }

    .last-visible-when-collapsed {
      margin-bottom: 0;
    }

  }
}

.avatar-container {
  border-radius: 50px;
  overflow: hidden;
  width: 48px;
  height: 48px;
  margin-bottom: @paragraph-margin-bottom;
  background-color: @gray-lighter;
  color: @body-bg;
  padding: 2px 8px;

  &.has-avatar {
    padding:0;
  }
}

.forum-comment {
  border-bottom: @separator-border-dotted;
  float: left;
  margin-bottom: @paragraph-margin-bottom;
  width: 100%;
}

.forum-comment-action {
  float: left;
  width: 100%;
  margin-bottom: @paragraph-margin-bottom;

  .action-button {
      margin-left: 10px;
  }
}

.forum-topic {
  margin-top: @headings-margin-top;

  .forum-comment-action {
    margin-bottom: 2 * @paragraph-margin-bottom;
  }
}

.forum-comment-removed {
  color: @gray-light;
}

.hilight-color1 {
  color: @color-secondary-swatch-e;
}

.hilight-color2 {
  color: @color-secondary-swatch-b;
}

.hilight-color3 {
  color: @color-secondary-swatch-c;
}

.badge-primary-color1 {
  background-color: @color-swatch-e;
}

.fa-primary-color1 {
  color: @color-swatch-e;
}

/*
 * Groups dashborad related
 */

.dashboard-group-heading {
  position: relative;
  min-width: 360px;

  > small {
    padding-top: floor(@line-height-computed - (@line-height-base * @font-size-small / 2))  ;
    font-size: @font-size-small;
    letter-spacing: normal;
    position: absolute;
    right: 0;
    color: inherit;
  }
}

.dashboard-group-list {
  list-style: none;
  margin: 0 -15px 0 -15px;
}

.dashboard-group-list-item {
  margin: 15px;
  padding-right: 0;
  padding-left: 0;
  float:left;
  width:95%;
}

.dashboard-group-list-item-link {
  display: block;
  width: 100%;
  overflow: auto;
  text-overflow: ellipsis;
  text-align: center;
  border-right: @dashboard-group-item-border;
  border-left: @dashboard-group-item-border;
  padding-right: @nav-link-padding-vertical;
  padding-left: @nav-link-padding-vertical;

  &:hover, &:focus {
    text-decoration: none;
  }

  .badge-pending-approvals {
    font-size: @font-size-base;
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.dashboard-group-list-item-info {
  display: table;
  height: 100%;
  width: 100%;

  > span {
    display: table-cell;
    vertical-align: middle;
  }
}

.dashboard-group-item-status-message, .dashboard-group-list-item-notifs {
  border: @dashboard-group-item-border;
  line-height: @dashboard-group-notifs-height-lg - @dashboard-group-item-border-width;
  border-top: 0;
}

.dashboard-group-item-status-message {
  padding-left: 15px;
}

.dashboard-group-list-item-notifs {
  padding: 0 15px;
  height: @dashboard-group-notifs-height-lg; // height required when no notifications
  position: relative;

  li {
    margin-right: 10px;
  }

  a {
    .reveal-on-hover {
      display: inline-block;
      position: absolute;
      width: 100%;
      left: 0;
      background-color: #EBF8FD;
      top: 0;
      height: 0;
      overflow: hidden;
      line-height: @line-height-computed + 2*@nav-link-padding-vertical;
      padding-left: @nav-link-padding-horizontal;
      .transition(top .3s, height .3s;);
    }
  }

  a:hover, a:focus {
    text-decoration: none;
    color: @link-color;

    .reveal-on-hover {
      top: -(@line-height-computed + 2*@nav-link-padding-vertical);
      height: @line-height-computed + 2*@nav-link-padding-vertical;
      color: @text-color;
    }
  }
}

.dashboard-group-list-item-link-normal {
  border-top: 3px solid @color-swatch-b;

  &:hover, &:focus {
    background-color: @color-swatch-b;
    border-color: @color-swatch-b;
    color: @body-bg;
  }
}

.dashboard-group-list-item-link-pending {
  border-top: 3px solid @gray-light;
  background-color: @gray-lightest;
  color: @text-color;

  &:hover, &:focus {
    cursor: default;
    background-color: @gray-lightest !important;
    color: @text-color !important;
  }
}

.dashboard-group-invited {
  .dashboard-group-list-item-link {
    border-top: 3px solid @color-secondary-swatch-a;
    background-color: @color-secondary-swatch-a;
    border-color: @color-secondary-swatch-a;
    color: @body-bg;

    &:hover, &:focus {
      background-color: darken(@color-secondary-swatch-a, 10);
      border-color: darken(@color-secondary-swatch-a, 10);
    }
  }

  .dashboard-group-list-item-info {
    display: block;
  }
}

.dashboard-group-invitation-icon {
  display: table-cell;

  .fa-xs-normal-size {
    padding-right: 10px;
  }
}

.dashboard-group-create {
  border: 0;

  .dashboard-group-list-item-info {
    display: inline-block;
  }
}

.dashboard-group-list-item-link-create {
  border: 0;
  text-align: left;

  &:hover, &:focus {
    border-color: darken(@color-swatch-b, 20);
    color: darken(@link-color, 20) !important;
  }
}

.dashboard-group-list-item-link-normal {
  &:hover, &:focus {
    background-color: @color-swatch-b !important;
    border-color: @color-swatch-b;
    color: @body-bg !important;

    .group-public {
      color: @body-bg;
    }

  }
}

/* Service Directory */

.tt-suggestion.tt-is-under-cursor {
  background-color: darken(@highlight-row, 20%);
  color: #fff;
}
